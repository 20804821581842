import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';
import ValidatorHelper from '../../../../helpers/ValidatorHelper';

import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import NumberInput from '../../../../components/NumberInput';

import * as fileTypes from '../../../../redux/futures/file/types';
import fileActions from '../../../../redux/futures/file/actions';

import * as productVariantTypes from '../../../../redux/futures/product_variant/types';
import productVariantAction from '../../../../redux/futures/product_variant/actions';

import productActions from '../../../../redux/futures/product/actions';

import Popover from '../../../../components/Popover';
import Image from '../../../../components/Image';
import LoadingScene from '../../../../components/LoadingScene';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCreate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, versionSelected, addVersion, updateVersion, indexSelected, productData } = props;

    const productReducer = useSelector((state) => state.product);
    const productVariantReducer = useSelector((state) => state.variant);
    const storeReducer = useSelector((state) => state.store);
    const productImageReducer = useSelector((state) => state.productImage);
    const fileReducer = useSelector((state) => state.file);

    const loadingVariant = useMemo(() => {
        return productVariantReducer.loading || false;
    }, [productVariantReducer.loading]);

    const loadingFile = useMemo(() => {
        return fileReducer.loading || false;
    }, [fileReducer.loading]);

    const fileRef = useRef();
    const imageRef = useRef();

    const versionDefault = {
        name: '',
        description: '',
        file: '',
        price: '',
        download_count: 3,
        is_download_redirect: false,
        src: '',
    };

    const [data, setData] = useState(versionDefault);
    const [selectedUrlImage, setSelectedUrlImage] = useState('');
    const [fileUrlImage, setFileUrlImage] = useState('');
    const [isShowPopover, setIsShowPopover] = useState(props.isShowPopover || true);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_VARIANT_IMAGE) {
            if (fileReducer.status) {
                let ticket_images = fileReducer.ticket_images;
                setData({
                    ...data,
                    src: ticket_images[0],
                });
                window.LadiUI.toastCustom('success', '', productImageReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }

        if (fileReducer.action == fileTypes.UPLOAD_FILE_DIGITAL) {
            if (fileReducer.status) {
                let file_url = fileReducer.file_url;

                setData({
                    ...data,
                    file: file_url,
                });
                window.LadiUI.toastCustom('success', '', productImageReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
    }, [fileReducer.action]);

    useEffect(() => {
        if (productVariantReducer.action == productVariantTypes.CREATE_VARIANT) {
            if (productVariantReducer.status) {
                hideModal();
                dispatch(productActions.reload(productData.product_id));
            } else window.LadiUI.showErrorMessage('Thông báo', productVariantReducer.message, 'OK');
        }
        if (productVariantReducer.action == productVariantTypes.UPDATE_VARIANT) {
            if (productVariantReducer.status) {
                hideModal();
                dispatch(productActions.reload(productData.product_id));
            } else window.LadiUI.showErrorMessage('Thông báo', productVariantReducer.message, 'OK');
        }
    }, [productVariantReducer.action]);

    useEffect(() => {
        if (versionSelected) {
            setData({
                name: versionSelected.name,
                description: versionSelected.description,
                file: versionSelected.file,
                price: versionSelected.price,
                download_count: versionSelected.download_count,
                is_download_redirect: versionSelected.is_download_redirect || false,
                src: versionSelected.src,
            });
        } else {
            setData(versionDefault);
        }
    }, [versionSelected]);

    const onOk = () => {
        if (baseHelper.isEmpty(data.name)) {
            window.LadiUI.toastCustom('danger', '', t('PRODUCTS.NAME_EMPTY', t('PRODUCTS.DIGITAL.DIGITAL').toLowerCase()));
            return;
        }

        if (!versionSelected || indexSelected < 0) {
            if (!productData || !productData.product_id) addVersion(data);
            else {
                let optionSelected = productData.options[0];
                // [
                //     {
                //         variant_value_id: '16130',
                //         option_id: '2396',
                //         option_name: 'Version',
                //         option_value_id: '5796',
                //         option_value_value: {
                //             name: 'phiên bản miễn phí',
                //         },
                //     },
                // ];
                let variants = productData.variants || [];

                let option = {
                    option_id: optionSelected.option_id,
                    option_name: t('PRODUCTS.VARIANT_VERSION_DEFAULT'),
                    [t('PRODUCTS.VARIANT_VERSION_DEFAULT')]: data.name,
                    option_value_value: {
                        name: data.name,
                    },
                };

                let variantData = {
                    ...data,
                    product_id: productData.product_id,
                    option_value_value: data.name,
                    options: [option],
                    position: variants.length,
                };
                dispatch(productVariantAction.create(variantData));
            }
        } else {
            if (productData && productData.product_id) {
                let optionSelected = versionSelected.options[0];

                let option = {
                    [t('PRODUCTS.VARIANT_VERSION_DEFAULT')]: data.name,
                    option_id: optionSelected.option_id,
                    option_name: t('PRODUCTS.VARIANT_VERSION_DEFAULT'),
                    option_value_id: optionSelected.option_value_id,
                    variant_value_id: optionSelected.variant_value_id,
                    option_value_value: {
                        name: data.name,
                    },
                };
                let variantData = {
                    ...data,
                    product_id: productData.product_id,
                    variant_id: versionSelected.product_variant_id,
                    product_variant_id: versionSelected.product_variant_id,
                    options: [option],
                };
                dispatch(productVariantAction.update(variantData));
            } else updateVersion(data);
        }
    };
    const onCancel = () => {
        hideModal();
    };

    const handelChangeInput = (e) => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleEditorChange = (value) => {
        setData({
            ...data,
            description: value,
        });
    };

    const fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadTicketImage(form));
            }
        }
    };

    const versionFileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files, 25);
            if (form) {
                dispatch(fileActions.uploadFileDigital(form));
            }
        }
    };

    const handleClickUploadFile2 = () => {
        document.getElementById('input-upload-file-2').click();
    };
    const handleVersionUpload = () => {
        document.getElementById('version-file-upload').click();
    };

    const handleCopyLinkToClipboard = (dataFile) => {
        if (dataFile) {
            navigator.clipboard.writeText(dataFile);
            window.LadiUI.toastCustom('success', '', 'Đã copy link tài liệu vào clipboard!');
        }
    };

    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            setData({
                ...data,
                src: selectedUrlImage,
            });

            setSelectedUrlImage('');
            imageRef.current.instanceRef.hide();
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };
    const handelFileUpload = () => {
        if (fileUrlImage && ValidatorHelper.isValidHttpUrl(fileUrlImage)) {
            setData({
                ...data,
                file: fileUrlImage,
            });

            setFileUrlImage('');
            fileRef.current.instanceRef.hide();
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    let prefixCDN = true;
    if (data.src && data.src.startsWith('http')) {
        prefixCDN = false;
    }

    const deleteImage = () => {
        setData({
            ...data,
            src: '',
        });
        const updateVariant = {
            product_id: data.product_id,
            variant_id: data.variant_id,
            src: '',
            src_hash: '',
        };

        dispatch(productVariantAction.changeImage(updateVariant));
    };

    return (
        <Modal
            id='modal-product-digital-create'
            title={t('PRODUCTS.DIGITAL.VERSION_CREATE_LB')}
            sub_title={''}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={800}
            isLoading={loadingVariant || loadingFile}
            position_unset={true}
            bodyClassName='laidui background-xam'
        >
            <div className='product-digital-content'>
                {loadingFile && <LoadingScene blur={true} />}
                <input
                    className='cursor-pointer display-none'
                    id='version-file-upload'
                    type='file'
                    name='file'
                    // accept='image/*'
                    // multiple='multiple'
                    onChange={versionFileChangedHandler}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                />
                <div className='ladiui container-place '>
                    <div className='ladiui label-18'>{t('PRODUCTS.DIGITAL.VERSION_LB')}</div>
                    <div className='mt-24'>
                        <div className='ladiui text-14'>{t('PRODUCTS.DIGITAL.VERSION_NAME')}</div>
                        <Input value={data.name} name='name' onChange={handelChangeInput} className='mt-4' />
                    </div>
                    <div className='mt-24'>
                        <div className='ladiui text-14'>{t('PRODUCTS.DIGITAL.VERSION_DES')}</div>
                        <div className='mt-4'>
                            <textarea
                                rows='4'
                                className='ladiui text-area'
                                value={data.description}
                                name='description'
                                onChange={handelChangeInput}
                            />
                        </div>
                    </div>
                </div>
                <div className='ladiui container-place mt-20'>
                    <div className='ladiui label-18'>{t('PRODUCTS.DIGITAL.VERSION_CONTENT')}</div>
                    <div className='mt-24'>
                        {!data.file ? (
                            <div className='group-upload-image mt-24'>
                                <i className='new-ldicon-upload' />
                                <div className='display-flex-center gap-4 mt-16'>
                                    <a onClick={() => handleVersionUpload()}>Tải file lên</a>
                                    <span>hoặc</span>
                                    <Popover
                                        ref={fileRef}
                                        wrapperProps={{ className: 'relative' }}
                                        wrapperPopoverProps={{ className: 'popover-image-product ' }}
                                        content={
                                            <div className='popover-add-image-product'>
                                                <div className='popover'>
                                                    <div className='ladiui form-group'>
                                                        <label className='ladiui-label new text-left'>URL</label>
                                                        <Input
                                                            className='ladiui form-control'
                                                            placeholder='https://'
                                                            name='image_url'
                                                            value={fileUrlImage}
                                                            onChange={(event) => {
                                                                setFileUrlImage(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='action-footer'>
                                                        <button
                                                            className='ladiui btn btn-secondary'
                                                            onClick={() => fileRef.current.instanceRef.hide()}
                                                        >
                                                            Hủy
                                                        </button>
                                                        <button className='ladiui btn btn-primary' onClick={handelFileUpload}>
                                                            Thêm nội dung
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <a>thêm từ URL</a>
                                    </Popover>
                                </div>
                                <div className='mt-8'>{t('PRODUCTS.FILE_SUGGEST')}</div>
                            </div>
                        ) : (
                            <div className='display-flex-center version-file-content'>
                                <div className='version-action-file-icon'>
                                    <i className='ladiui icon-new new-ldicon-file' />
                                </div>
                                <div className='display-flex-center version-file-url' onClick={() => handleCopyLinkToClipboard(data.file)}>
                                    <span className='version-url'> {data.file}</span>
                                    <i className='ladiui icon-new new-ldicon-copy' />
                                </div>

                                <div className='version-action-icon' onClick={() => handleVersionUpload()}>
                                    <i className='ladiui icon-new new-ldicon-upload' />
                                </div>

                                <Popover
                                    ref={fileRef}
                                    wrapperProps={{ className: 'relative' }}
                                    wrapperPopoverProps={{ className: 'popover-version-file ' }}
                                    content={
                                        <div className='popover-add-image-product'>
                                            <div className='popover'>
                                                <div className='ladiui form-group pb-20'>
                                                    <label className='ladiui-label new text-left'>URL</label>
                                                    <Input
                                                        className='ladiui form-control'
                                                        placeholder='https://'
                                                        name='image_url'
                                                        value={fileUrlImage}
                                                        onChange={(event) => {
                                                            setFileUrlImage(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='ladiui pt-12 flex-center-between border-t-50p '>
                                                    <button
                                                        className='ladiui btn btn-secondary'
                                                        onClick={() => fileRef.current.instanceRef.hide()}
                                                    >
                                                        Hủy
                                                    </button>
                                                    <button className='ladiui btn btn-primary' onClick={handelFileUpload}>
                                                        Thêm nội dung
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className='version-action-icon'>
                                        <i className='ladiui icon-new new-ldicon-link2' />
                                    </div>
                                </Popover>

                                <div className='version-action-icon' onClick={() => setData({ ...data, file: '' })}>
                                    <i className='ladiui icon-new new-ldicon-delete' />
                                </div>
                            </div>
                        )}
                    </div>
                    {data.file && (
                        <div className='mt-12 ladiui flex-center-start gap-8'>
                            <div className='ladiui item-form switch'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        onChange={(event) => {
                                            setData({
                                                ...data,
                                                is_download_redirect:
                                                    data.is_download_redirect == undefined ? true : !data.is_download_redirect,
                                            });
                                        }}
                                        checked={data.is_download_redirect || false}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <span>{t('PRODUCTS.DIGITAL.DOWNLOAD_REDIRECT')}</span>
                        </div>
                    )}
                </div>

                <div className='ladiui container-place mt-20'>
                    <div className='ladiui label-18'>{t('PRODUCTS.DIGITAL.VERSION_PRICE_LB')}</div>
                    <div className='mt-24 ladiui display-flex-center gap-16 w-100-p'>
                        <div className='w-100-p'>
                            <div className='ladiui text-14'>{t('PRODUCTS.DIGITAL.VERSION_PRICE_TT')}</div>
                            <NumberInput
                                className='mt-4'
                                name='price'
                                min={0}
                                suffix={storeReducer.userInfo.currentStore.currency_symbol}
                                value={data.price}
                                onChange={handelChangeInput}
                            />
                        </div>
                        <div className='w-100-p'>
                            <div className='ladiui text-14'>{t('PRODUCTS.DIGITAL.VERSION_NUM_DOWNLOAD_TT')}</div>
                            <Input value={data.download_count} name='download_count' onChange={handelChangeInput} className='mt-4' />
                        </div>
                    </div>
                </div>

                <div className='ladiui container-place mt-20 product-degital'>
                    <div className='ladiui label-18'>{t('PRODUCTS.DIGITAL.VERSION_IMAGE_LB')}</div>

                    <div className='version-upload-image mt-24'>
                        <Image width={400} resize={appConfig.RESIZE.SMALL} src={data.src} prefixCDN={prefixCDN} />
                        <div className='group-upload-image'>
                            <i className='new-ldicon-photo' />
                            <div className='display-flex-center gap-4 mt-16'>
                                <a onClick={() => handleClickUploadFile2()}>Tải ảnh lên</a>
                                <span>hoặc</span>
                                <Popover
                                    ref={imageRef}
                                    wrapperProps={{ className: 'relative' }}
                                    wrapperPopoverProps={{ className: 'popover-image-product ' }}
                                    isShowPopover={isShowPopover}
                                    content={
                                        <div className='popover-add-image-product'>
                                            <div className='popover'>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                    <Input
                                                        className='ladiui form-control'
                                                        placeholder='Nhập đường dẫn sản phẩm'
                                                        name='image_url'
                                                        value={selectedUrlImage}
                                                        onChange={(event) => {
                                                            setSelectedUrlImage(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className='action-footer'>
                                                    <button
                                                        className='ladiui btn btn-secondary'
                                                        onClick={() => imageRef.current.instanceRef.hide()}
                                                    >
                                                        Hủy
                                                    </button>
                                                    <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                        Thêm hình ảnh
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <a>thêm từ URL</a>
                                </Popover>
                            </div>
                            <div className='mt-8'>{t('PRODUCTS.IMAGE_SUGGEST')}</div>

                            {data.src && (
                                <div className='btn-delete-variant-image' onClick={deleteImage}>
                                    <i className='ladiui icon-new new-ldicon-delete' />
                                    <span>Xóa ảnh</span>
                                </div>
                            )}
                            <input
                                className='cursor-pointer display-none'
                                id='input-upload-file-2'
                                type='file'
                                name='file'
                                accept='image/*'
                                // multiple='multiple'
                                onChange={fileChangedHandler}
                                onClick={(event) => {
                                    event.target.value = null;
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCreate;
